.App {
    --scene-margin: 10px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App > main {
    flex-grow: 1;
}

