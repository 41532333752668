.ParentsManage {
    margin: var(--scene-margin);
}

.ParentsManage .BackupRestoreSegment button {
    min-width: 10em;
}

.ParentsManage .BackupRestoreSegment div.Backup {
    margin-bottom: 5px;
}
